/** @jsx jsx */
import { jsx } from '@emotion/react';
import { SupportedLocale } from '@sprinx/knihovna-types';
import React from 'react';
import { LocaleSwitchHrefs } from 'types/LocaleSwitchHrefs';
import { Navigation } from 'types/Navigation';
import MainLayoutCookieConsent from './components/AppLayoutCookieConsent';
import MainLayoutFooter from './components/MainLayoutFooter';
import MainLayoutHeader from './components/MainLayoutHeader';

export interface MainLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  header: {
    navigation: Navigation[];
  };
  locale?: SupportedLocale;
  localeHrefs?: LocaleSwitchHrefs;
}

const MainLayout: React.FC<MainLayoutProps> = ({ header, children, locale = 'en', localeHrefs }) => {
  return (
    <div css={{ alignItems: 'stretch', display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: '100vh' }}>
      <MainLayoutHeader locale={locale} localeHrefs={localeHrefs} {...header} />
      <main
        css={(theme) => ({
          '@media (min-width: 600px)': {
            paddingTop: theme.spacing(12),
          },
          '@media(min-width: 960px)': {
            paddingTop: 0,
          },
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'flex-start',
          paddingTop: theme.spacing(8),
        })}
      >
        {children}
      </main>
      <MainLayoutFooter locale={locale} />
      <MainLayoutCookieConsent locale={locale} />
    </div>
  );
};

MainLayout.displayName = 'MainLayout';

export default MainLayout;
