import { SupportedLocale } from '@sprinx/knihovna-types';
import { Navigation } from 'types/Navigation';

const navigation: { cs: Navigation[]; en: Navigation[] } = {
  cs: [
    {
      href: 'about',
      label: 'Naše společnost',
      subMenu: [
        { href: 'about', label: 'O skupině Plaček', params: 'brief' },
        { href: 'about', label: 'Naše značkové obchody', params: 'numbers' },
        { href: 'about', label: 'Naše historie', params: 'history' },
        { href: 'about', label: 'Náš obchodní model - Omnichannel', params: 'business' },
        { href: 'about', label: 'Naše představenstvo', params: 'directors' },
      ],
    },
    {
      href: 'brand',
      label: 'Naše značky',
      subMenu: [
        {
          href: 'brand',
          label: 'Ontario',
          params: '0',
        },
        {
          href: 'brand',
          label: 'Prospera Plus',
          params: '2',
        },
        {
          href: 'brand',
          label: 'Rasco Premium',
          params: '4',
        },
        {
          href: 'brand',
          label: 'Repti Planet',
          params: '1',
        },
        {
          href: 'brand',
          label: 'Nature Land',
          params: '3',
        },
        {
          href: 'brand',
          label: 'Goods a další portfolio',
          params: '5',
        },
      ],
    },
    {
      href: 'engagement',
      label: 'Komunitní angažovanost',
    },
    {
      href: 'stores',
      label: 'Naši prodejci',
    },
  ],
  en: [
    {
      href: 'about',
      label: 'Our Company',
      subMenu: [
        { href: 'about', label: 'About Plaček Group', params: 'brief' },
        { href: 'about', label: 'Our Branded Stores', params: 'numbers' },
        { href: 'about', label: 'Our History', params: 'history' },
        { href: 'about', label: 'Our Business Model - Omnichannel', params: 'business' },
        { href: 'about', label: 'Our Board of Directors', params: 'directors' },
      ],
    },
    {
      href: 'brand',
      label: 'Our Brands',
      subMenu: [
        {
          href: 'brand',
          label: 'Ontario',
          params: '0',
        },
        {
          href: 'brand',
          label: 'Prospera Plus',
          params: '2',
        },
        {
          href: 'brand',
          label: 'Rasco Premium',
          params: '4',
        },
        {
          href: 'brand',
          label: 'Repti Planet',
          params: '1',
        },
        {
          href: 'brand',
          label: 'Nature Land',
          params: '3',
        },
        {
          href: 'brand',
          label: 'Goods and Other Brands',
          params: '5',
        },
      ],
    },
    {
      href: 'engagement',
      label: 'Community  Engagement',
    },
  ],
};

const getNavigation = (locale: SupportedLocale): Navigation[] => navigation[locale];

export default getNavigation;
