/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { SupportedLocale } from '@sprinx/knihovna-types';
import React from 'react';
import translate from 'translate';

export interface MainLayoutCookieConsentProps extends React.HTMLAttributes<HTMLDivElement> {
  locale: SupportedLocale;
}

const MainLayoutCookieConsent = React.forwardRef<HTMLDivElement, MainLayoutCookieConsentProps>(
  ({ locale, ...rest }, ref) => {
    const [render, setRender] = React.useState(true);
    const t = translate(locale);
    const handleChange = React.useCallback(() => {
      const date = new Date();
      const nextMonth = new Date(date.setMonth(date.getMonth() + 1));

      document.cookie = `cookie-consent=true; expires=${nextMonth}`;
      setRender(false);
    }, []);

    React.useEffect(() => {
      if (document.cookie.split('; ').find((row) => row.startsWith('cookie-consent'))) {
        setRender(false);
      } else {
        setRender(true);
      }
    }, []);

    return render ? (
      <div
        css={css({
          backgroundColor: '#283862',
          bottom: 0,
          left: 0,
          padding: '20px 0',
          position: 'fixed',
          width: '100%',
        })}
        ref={ref}
        {...rest}
      >
        <div
          css={css({
            '@media(max-width: 599px)': { alignItems: 'flex-start', flexDirection: 'column' },
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '0 auto',
            padding: '0 20px',
          })}
        >
          <div>
            <span
              css={css({
                color: '#FFF',
                fontSize: '16px',
              })}
            >
              {t('cookieconsent.text')}
            </span>
          </div>
          <button
            css={css({
              '@media(max-width: 599px)': { margin: '10px auto 0 auto', padding: '8px 30px' },
              background: '#FFF',
              border: 'none',
              borderRadius: '3px',
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
              color: '#283862',
              cursor: 'pointer',
              display: 'flex',
              fontSize: '22px',
              justifyContent: 'center',
              outline: 'unset',
              padding: '8px 15px',
              textTransform: 'uppercase',
              transition: 'all 0.2s ease',
            })}
            onClick={handleChange}
          >
            ok
          </button>
        </div>
      </div>
    ) : null;
  },
);

MainLayoutCookieConsent.displayName = 'MainLayoutCookieConsent';

export default MainLayoutCookieConsent;
