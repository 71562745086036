/** @jsx jsx */
import { ClassNames, jsx, Theme } from '@emotion/react';
import { SupportedLocale } from '@sprinx/knihovna-types';
import subscribeToNewsletter from 'api/subscribeToNewsletter';
import Button from 'components/Button';
import Container from 'components/Container';
import Logo from 'icons/Logo';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import theme from 'theme';
import translate from 'translate';

export interface MainLayoutFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  locale: SupportedLocale;
}

const MainLayoutFooter: React.FC<MainLayoutFooterProps> = ({ locale, ...rest }) => {
  return (
    <footer
      {...rest}
      css={(theme: Theme) => ({
        alignItems: 'center',
        background: theme.palette.footer.background,
        color: theme.palette.footer.textPrimary,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(4, 0, 6, 0),
      })}
    >
      <Container
        css={{
          '@media(min-width: 600px)': {
            '& > div': {
              margin: theme.spacing(0, 2),
            },
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: theme.spacing(0, 2),
          },
          '@media(min-width: 960px)': {},
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: theme.spacing(0, 1),
        }}
        maxWidth='lg'
      >
        <FooterLogo />
        <FooterNewsletter locale={locale} />
        <FooterCustomerSupport locale={locale} />
        {/* <FooterSocialMedia /> */}
      </Container>
      <div
        css={(theme) => ({
          fontSize: 14,
          marginTop: theme.spacing(6),
        })}
      >
        {'© 2021 Plaček Pet Products s.r.o. All rights reserved. '}
      </div>
    </footer>
  );
};

MainLayoutFooter.displayName = 'MainLayoutFooter';

export default MainLayoutFooter;

const FooterLogo: React.FC<{}> = () => {
  return <Logo />;
};

FooterLogo.displayName = 'FooterLogo';

const FooterSocialMedia: React.FC<{}> = () => {
  return (
    <FooterContainer>
      <FooterHeadline>{'Follow us'}</FooterHeadline>
      <div
        css={(theme) => ({
          '& > a': {
            '&:first-of-type': {
              marginLeft: 0,
            },
            '&:last-of-type': {
              marginRight: 0,
            },
            margin: theme.spacing(0, 1),
          },
          '& svg': {
            '&:hover': {
              fill: theme.palette.grey[400],
            },
            fill: theme.palette.common.white,
            height: 32,
            transition: 'all .2s ease',
            width: 32,
          },
          display: 'flex',
        })}
      >
        {/* <IconButton href='https://www.facebook.com//KrmivoOntario' title='facebook'>
          <FacebookIcon />
        </IconButton>
        <IconButton href='https://www.placek.cz/' title='instragram'>
          <InstagramIcon />
        </IconButton>
        <IconButton href='https://plus.google.com/115743257406752028999/posts?hl=cs' title='googleplus'>
          <GooglePlusIcon />
        </IconButton>
        <IconButton href='https://www.youtube.com/user/placekcz?feature=watch' title='youtube'>
          <YoutubeIcon />
        </IconButton> */}
      </div>
    </FooterContainer>
  );
};

FooterSocialMedia.displayName = 'FooterSocialMedia';

const FooterCustomerSupport: React.FC<{ locale: SupportedLocale }> = ({ locale }) => {
  // const footerNavigation = useFooterNavigation();
  const t = translate(locale);
  return (
    <FooterContainer>
      <FooterHeadline>{'Plaček group'}</FooterHeadline>
      <FooterContainer
        css={(theme) => ({
          '& > a': {
            '&:hover': {
              textDecoration: 'underline',
            },
            color: theme.palette.common.white,
            margin: theme.spacing(0.5, 0),
            textDecoration: 'none',
            transition: 'all .2s ease',
          },
          '@media(min-width: 600px)': {
            alignItems: 'flex-end',
          },
          alignItems: 'center',
        })}
      >
        {/* {(footerNavigation || []).map((item, idx) => (
          <Link to={item.href} key={idx.toString()}>
            {item.label}
          </Link>
        ))} */}
        <Link to={routeUrl('contact', { locale, params: { locale } })}>{t('footer.contacts')}</Link>
        <Link to={routeUrl('about', { locale, params: { locale } })}>{t('footer.ourCompany')}</Link>
        <Link to={routeUrl('brand', { locale, params: { locale } })}>{t('footer.ourBrands')}</Link>
        <a href='https://all.placek.cz' target='_blank' rel='noreferrer'>
          CZ website
        </a>
      </FooterContainer>
    </FooterContainer>
  );
};

FooterCustomerSupport.displayName = 'FooterCustomerSupport';

interface IFormInput {
  email: string;
}

const FooterNewsletter: React.FC<{ locale: SupportedLocale }> = ({ locale }) => {
  const [isSaved, setSaved] = React.useState<boolean>(false);
  const t = translate(locale);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid, isDirty },
    reset,
  } = useForm<IFormInput>({ defaultValues: { email: '' }, mode: `onChange` });

  const onSubmit = async (data: IFormInput) => {
    setSaved(false);
    await subscribeToNewsletter({ email: data.email, path: window.location.pathname });
    reset({ email: '' });
    setSaved(true);
  };
  return (
    <FooterContainer>
      <FooterHeadline>{'Newsletter'}</FooterHeadline>
      <form
        onSubmit={handleSubmit(onSubmit)}
        css={{ alignItems: 'center', display: 'flex', flexDirection: 'row', position: 'relative', width: 290 }}
      >
        <NewsletterField
          name='email'
          ref={register({
            pattern: {
              message: 'Email musí být ve formátu xx@xx.xx',
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
            },
            required: true,
          })}
          locale={locale}
          placeholder={t('newsletter.placeholder')}
          css={(theme) => ({
            '& input': {
              '@media(min-width: 600px)': {
                padding: theme.spacing(1.25, 14, 1.25, 2),
              },
              padding: theme.spacing(2, 14, 2, 2),
            },
          })}
        />
        <Button
          type='submit'
          disabled={isSubmitting}
          css={(theme) => ({
            '&:hover': {
              background: theme.palette.primary.dark,
              color: theme.palette.common.white,
            },
            '@media(min-width: 600px)': {
              fontSize: 16,
              padding: theme.spacing(1.25, 2),
            },
            background: theme.palette.grey[400],
            borderRadius: 0,
            color: theme.palette.text.primary,
            fontSize: 14,
            padding: theme.spacing(2),
            position: 'absolute',
            right: 0,
            textTransform: 'none',
            top: '50%',
            transform: 'translate(0, -50%)',
          })}
        >
          {t('newsletter.subscribe')}
        </Button>
      </form>
      <div
        css={(theme) => ({
          marginTop: theme.spacing(1),
        })}
      >
        {errors?.email?.message}
        {isSaved && isValid && !isDirty && 'Děkujeme za odeslání.'}
      </div>
    </FooterContainer>
  );
};

const FooterHeadline: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <h3
      css={(theme) => ({
        color: theme.palette.common.white,
        fontSize: 24,
        margin: theme.spacing(2, 0),
      })}
    >
      {children}
    </h3>
  );
};

FooterHeadline.displayName = 'FooterHeadline';

const FooterContainer: React.FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => {
  return (
    <ClassNames>
      {({ cx, css }) => (
        <div
          className={cx(
            css({
              display: 'flex',
              flexDirection: 'column',
            }),
            className,
          )}
        >
          {children}
        </div>
      )}
    </ClassNames>
  );
};

FooterContainer.displayName = 'FooterContainer';

export interface NewsletterFieldProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  locale: SupportedLocale;
  name: string;
  placeholder?: any;
}

const NewsletterField = React.forwardRef<HTMLInputElement, NewsletterFieldProps>(
  ({ className, children, placeholder, locale, name, ...rest }, ref) => {
    return (
      <ClassNames>
        {({ cx, css, theme }) => (
          <div
            className={cx(
              css({
                boxShadow: theme.shadows[6],
                display: 'flex',
                maxWidth: '100%',
                overflow: 'hidden',
                width: '100%',
              }),
              className,
            )}
            {...rest}
          >
            <input
              name={name}
              ref={ref}
              type='text'
              placeholder={placeholder}
              css={{
                '&::placeholder': {
                  color: theme.palette.text.secondary,
                  fontSize: 14,
                },
                border: 'none',
                fontSize: 14,
                outline: 'none',
                padding: theme.spacing(1.25, 2),
                transition: 'all .2s ease',
                width: '100%',
              }}
            />
            <label htmlFor={name} css={{ height: 0, opacity: 0, width: 0 }}>
              {name}
            </label>
          </div>
        )}
      </ClassNames>
    );
  },
);

NewsletterField.displayName = 'NewsletterField';
