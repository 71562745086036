/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { ClassNames, jsx } from '@emotion/react';
import { SupportedLocale } from '@sprinx/knihovna-types';
import Container from 'components/Container';
import Logo from 'icons/Logo';
import React from 'react';
import HeadShake from 'react-reveal/HeadShake';
import { Link } from 'react-router-dom';
import { routeUrl } from 'routesBuilder';
import translate from 'translate';
import { LocaleSwitchHrefs } from 'types/LocaleSwitchHrefs';
import { Navigation } from 'types/Navigation';
import LangSwitcher from './LangSwitcher';

export interface MainLayoutHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  locale: SupportedLocale;
  localeHrefs?: LocaleSwitchHrefs;
  navigation: Navigation[];
}

const MainLayoutHeader: React.FC<MainLayoutHeaderProps> = ({ navigation, locale, localeHrefs, ...rest }) => {
  const [open, setOpen] = React.useState(false);

  const t = translate(locale);
  const handleClose = React.useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  return (
    <nav
      {...rest}
      css={(theme) => ({
        '@media(min-width: 600px)': {
          height: theme.spacing(12.5),
        },
        '@media(min-width: 960px)': {
          position: 'relative',
        },
        background: theme.palette.header.background,
        color: theme.palette.header.textPrimary,
        display: 'flex',
        height: theme.spacing(8),
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 10,
      })}
    >
      <Container
        css={(theme) => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          padding: theme.spacing(0, 2),
        })}
        maxWidth='lg'
      >
        <MainLayoutHeaderLogo locale={locale} />
        {open && (
          <div
            css={{
              '@media(min-width: 960px)': {
                display: 'none',
              },
              display: 'block',
              height: '100%',
              left: 0,
              position: 'fixed',
              top: 0,
              width: '100%',
            }}
            onClick={handleClose}
          ></div>
        )}
        <div
          css={(theme) => ({
            '@media(min-width: 600px)': {
              width: open ? '50%' : 0,
            },
            '@media(min-width: 960px)': {
              background: 'transparent',
              bottom: 'unset',
              boxShadow: 'none',
              flexDirection: 'row',
              overflow: 'unset',
              paddingTop: 0,
              position: 'static',
              right: 'unset',
              top: 'unset',

              width: 'auto',
            },
            alignItems: 'center',
            background: theme.palette.common.white,
            bottom: 0,
            boxShadow: theme.shadows[6],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            paddingTop: theme.spacing(12),
            position: 'fixed',
            right: 0,
            top: 0,
            transition: 'width .3s ease',
            width: open ? '75%' : 0,
            zIndex: 5,
          })}
        >
          {(navigation || []).map((item, idx) => (
            <MainLayoutHeaderLink key={idx.toString()} open={open} item={item} locale={locale} onClose={handleClose} />
          ))}
          );
          <LangSwitcher locale={locale} localeHrefs={localeHrefs} />
          <HeadShake delay={5000}>
            <Button locale={locale}>{t('navigation.contactUs')}</Button>
          </HeadShake>
        </div>
        <HamburgerButton open={open} onChange={() => setOpen((ps) => !ps)} />
      </Container>
    </nav>
  );
};

MainLayoutHeader.displayName = 'MainLayoutHeader';

export default MainLayoutHeader;

const Button: React.FC<{ children: React.ReactNode; locale: SupportedLocale }> = ({ locale, children }) => {
  return (
    <a
      href={routeUrl('contact', { locale, params: { locale: locale } })}
      css={(theme) => ({
        '&:hover': {
          background: theme.palette.primary.dark,
          boxShadow: theme.shadows[6],
        },
        '@media(min-width: 960px)': {
          marginLeft: theme.spacing(2),
          marginTop: theme.spacing(0),
        },
        alignItems: 'center',
        alignSelf: 'center',
        background: theme.palette.primary.main,
        border: 'none',
        borderRadius: '3px',
        boxShadow: theme.shadows[3],
        color: theme.palette.common.white,
        cursor: 'pointer',
        display: 'flex',
        fontSize: '18px',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        outline: 'unset',
        padding: theme.spacing(1, 2),
        textDecoration: 'none',
        textTransform: 'uppercase',
        transition: 'all 0.2s ease',
      })}
    >
      {children}
    </a>
  );
};

const MainLayoutHeaderLogo: React.FC<{ locale: SupportedLocale }> = ({ locale }) => {
  return (
    <Link to={routeUrl('home', { locale, params: { locale: locale } })} css={{ alignSelf: 'center' }} title='logo'>
      <Logo
        css={(theme) => ({
          '@media (min-width:600px)': {
            width: 155,
          },
          fill: theme.palette.primary.main,
          width: 135,
        })}
      />
    </Link>
  );
};

MainLayoutHeaderLogo.displayName = 'MainLayoutHeaderLogo';

const HamburgerButton: React.FC<{ className?: string; onChange: () => void; open: boolean }> = ({
  open = false,
  onChange: handleChange,
  className,
}) => {
  return (
    <ClassNames>
      {({ cx, css, theme }) => (
        <button
          type='button'
          aria-label='hamburger'
          onClick={handleChange}
          className={cx(
            css({
              '& > div': {
                '&:first-of-type': {
                  transform: open ? 'rotate(45deg)' : 'rotate(0)',
                },
                '&:nth-of-type(2)': {
                  opacity: open ? 0 : 1,
                  transform: open ? 'translateX(-20px)' : 'translateX(0)',
                },
                '&:nth-of-type(3)': {
                  transform: open ? 'rotate(-45deg)' : 'rotate(0)',
                },
                background: theme.palette.text.primary,
                borderRadius: 10,
                height: 3,
                position: 'relative',
                transformOrigin: '1px',
                transition: 'all 0.2s linear',
                width: theme.spacing(4),
              },
              '&:focus': {
                outline: 'none',
              },
              '@media(min-width: 600px)': {
                top: theme.spacing(4),
              },
              '@media(min-width: 960px)': {
                display: 'none',
              },
              alignItems: 'center',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              height: theme.spacing(4),
              justifyContent: 'space-around',
              padding: 0,
              position: open ? 'fixed' : 'static',
              right: open ? theme.spacing(2) : 'auto',
              top: open ? theme.spacing(2) : 'auto',
              width: theme.spacing(4),
              zIndex: 10,
            }),
            className,
          )}
        >
          <div />
          <div />
          <div />
        </button>
      )}
    </ClassNames>
  );
};

const MainLayoutHeaderLink: React.FC<{
  item: Navigation;
  locale: SupportedLocale;
  onClose: () => void;
  open: boolean;
}> = ({ item, locale, onClose: handleClose, open = false }) => {
  return (
    <div
      css={(theme) => ({
        '& > div': {
          display: 'none',
        },
        '& a': {
          ...theme.typography.body1,
          '&:hover': {
            textDecoration: 'underline',
          },
          textDecoration: 'none',
          transition: 'all .2s ease',
        },
        '@media(min-width: 960px)': {
          '& > div': { '&:hover': { display: 'flex' } },
          '& a:hover + div': {
            display: 'flex',
          },
          margin: theme.spacing(0, 1),
          opacity: 1,
        },
        display: 'flex',
        margin: theme.spacing(2, 0),
        opacity: open ? 1 : 0,
        position: 'relative',
      })}
    >
      <Link to={routeUrl(item.href, { locale, params: { locale: locale } })} onClick={handleClose}>
        {item.label}
      </Link>
      {item.subMenu && item.subMenu.length > 0 && (
        <div
          css={(theme) => ({
            '& > a': {
              padding: theme.spacing(1, 0),
              whiteSpace: 'nowrap',
            },
            background: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderTop: 'none',
            flexDirection: 'column',
            padding: theme.spacing(1),
            position: 'absolute',
            top: '100%',
          })}
        >
          {item.subMenu.map((sub, idx) => (
            <a
              key={idx.toString()}
              href={`${routeUrl(item.href, { locale, otherParamsToQs: true, params: { locale: locale } })}#${
                sub.params
              }`}
              onClick={handleClose}
            >
              {sub.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
